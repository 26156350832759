import Pusher from 'pusher-js';
import store from '../store/index';
export default class psPusher {
  constructor ()
  {  
    // Pusher.logToConsole = true; 
    this.token();
    const pusherSetup = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      authEndpoint: process.env.VUE_APP_FIREBASE_FUNCTIONS + 'pusherAuth?upId=' + store.getters.userProfileId + '&token=' + store.getters.token,
      forceTLS: true
    });
    return pusherSetup;  
  }
  async token (){
    await store.dispatch('getToken'); 
  }

}